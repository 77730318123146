<template>
  <div class="list-info">
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入场所名或出租房名搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <van-popup v-model="belongShow" position="bottom"  >
      <van-picker title="任务归属" show-toolbar :columns="belongList" value-key="label" @confirm="belongConfirm" @cancel="belongShow = false"  />
    </van-popup>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" type="datetime" title="选择时间" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="datetime" title="选择时间" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="statusShow" position="bottom"  >
      <van-picker title="任务状态" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm" @cancel="statusShow = false"  />
    </van-popup>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="belongShow = !belongShow" :style="{color:belongShow?'#3E7FF6': '' }">{{belongTitle}}<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#3E7FF6': '' }">任务时间<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6">
          <p @click="statusShow = !statusShow" :style="{color:statusShow?'#3E7FF6': '' }">{{statusTitle}}<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6" @click="moreCancel">重置</van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
     <div class="cont">
       <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
         <van-cell>
           <template #title>
             <p class="list_totalCount">共有{{totalCount}}条数据</p>
           </template>
         </van-cell>
         <van-cell @click="goInfo(item)" v-for="(item, index) in dataList" :key="index">
           <van-cell-group >
             <van-cell class="title" center>
               <van-row>
                 <van-col :span="18">{{item.name}}</van-col>
                 <van-col :span="6" :style="{color:item.taskStatus == 0?'#1BD972': (item.taskStatus == -1? '#347DF5' : '#29BFB8')}">{{item.taskStatus == 0?'进行中':(item.taskStatus == -1? '待申领' : '已完成')}}</van-col>
               </van-row>
               <van-row>
                 <van-col :span="22">{{item.scopeName}}</van-col>
                 <van-col :span="2"><van-icon name="arrow" /></van-col>
               </van-row>
               <van-row>
                 <van-col :span="8">任务次数：<span :style="item.finished==item.times?'color: #2E73F8':'color: #FE9A30'">{{item.finished}}</span>/<span style="color: #2E73F8">{{item.times}}</span></van-col>
                 <van-col :span="16">
                   <van-button type="info" round size="small" v-show="item.taskStatus == -1" @click.stop="applyTask(item.id)" style="float:right">申领任务</van-button>
                   <van-button type="info" round size="small" v-show="item.taskStatus == 0 && item.handleId == null" style="float:right" @click.stop="goSubmit(item)">上传结果</van-button>
                 </van-col>
               </van-row>
             </van-cell>
             <van-cell class="bottom" center>
               <van-row>
                 <van-col :span="7" style="color:#666">{{item.scopeType === 1? '社区' : '网格'}}任务</van-col>
                 <van-col :span="12" style="color:#EA4749">{{item.endTime}}前完成</van-col>
               </van-row>
             </van-cell>
           </van-cell-group>
         </van-cell>
       </van-list>
       <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
     </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      activeName: '',
      loading: false,
      finished: false,
      totalCount: 0,
      limit: 10,
      page: 0,
      dataForm: {
        taskStatus: '',
        scopeType: '',
        visitType: '',
        objectType: ''
      },
      belongTitle: '任务对象',
      statusTitle: '任务状态',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      dataList: [],
      searchValue: '',
      belongList: [{ value: 1, label: "社区" }, { value: 2, label: "网格" }],
      statusList: [
        { label: '待申领', value: -1 },
        { label: '进行中', value: 0 },
        { label: '已完成', value: 1 }],
      visitTypeList: [],
      statusShow: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      belongShow: false,
      visitTypeShow: false,
    }
  },
  methods: {
    ...mapMutations,
    goInfo(item) {
      this.$store.commit('setOtherTask', item)
      this.$router.push('/otherInfo')
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/assignTasks/otherList'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          orgId: this.$orgId,
          status: this.dataForm.taskStatus,
          beginTime: this.selectDate.beginDate,
          endTime: this.selectDate.endDate,
          scopeType: this.dataForm.scopeType
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    onSearch() {
      this.page = 0
      if (this.searchValue.indexOf('-') > -1) {
        this.dataForm.objectType = 1
      } {
        this.dataForm.objectType = 2
      }
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.dataForm.objectType = ''
      this.finished = false
      this.getDataList()
    },
    moreCancel () {
      this.belongTitle = '任务对象'
      this.statusTitle = '任务状态'
      this.dataForm.taskStatus = ''
      this.dataForm.scopeType = 1
      this.dataForm.visitType = ''
      this.dataForm.objectType = ''
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    belongConfirm (value) {
      this.page = 0
      this.dataList = []
      this.dataForm.scopeType = value.value
      this.belongTitle = value.label
      this.getDataList()
      this.belongShow = false
    },
    statusConfirm (val) {
      this.dataForm.taskStatus = val.value
      this.statusTitle = val.label
      this.page = 0
      this.dataList = []
      this.getDataList()
      this.statusShow = false
    },
    //时间筛选
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    applyTask (id) {
      this.$dialog.confirm({
        message: '确定申领任务？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/app/assignTasks/claimOtherTask`),
          method: 'post',
          data: this.$http.adornData({
            'id': id
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '申领成功',
              duration: 1500,
              onClose: () => {
                this.page = 0
                this.dataList = []
                this.getDataList()
              }
            })
          }
        })
      })
    },
    goSubmit (item) {
      debugger
      this.$router.push({path: '/otherInfo' , query: {handleId: item.handleId}})
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    })
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding-top: 100px;
  .tab {
    //border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .cont {
    .van-cell {
      position: relative;
      padding: 15px 20px 0;
      margin-bottom: 30px;
      border-radius: 10px;

      .title {
        margin-bottom: 20px !important;

        .van-icon {
          font-size: 18px;
          margin-top: 10px;
        }

        .van-row:nth-child(1) .van-col:nth-child(1) {
          font-size: 34px;
          font-weight: 600;
        }

        .van-row:nth-child(1) .van-col:nth-child(2) {
          padding-left: 30px;
        }

        .van-row:nth-child(2) .van-col:nth-child(1) {
          font-size: 32px;
          color: #5d5d5d;
          margin-top: 10px;
        }

        .van-row:nth-child(3) .van-col:nth-child(1) {
          font-size: 32px;
          font-weight: 500;
          color: #000;
          margin-top: 10px;
        }

        .van-row:nth-child(4) {
          color: #5d5d5d;
        }

        .van-row:nth-child(4) .van-col:nth-child(2) {
          .van-button {
            padding: 0 37px;
            margin-left: 14px;
          }
        }
      }

      .bottom {
        border-top: 1px solid #F1F1F1;
        font-size: 28px;
        color: #333;
        text-align: center;
        margin: 20px 10px;
      }
    }
  }
}
::v-deep {
  .van-cell::after {
    border-bottom: none;
  }
  .van-cell-group::after {
    border-bottom: none;
  }
}
.list_totalCount {
  padding: 10px 20px;
}
</style>
